<template>
  <div id="app" style="width: 100%; height: 100%"></div>
</template>

<script>
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
  //  import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
  import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
  import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
  import {
    Clock,
    AnimationMixer,
    Group,
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    PlaneBufferGeometry,
    Mesh,
    MeshBasicMaterial,
    DoubleSide,
    GridHelper,
    AmbientLight,
    PointLight,
    AxesHelper,
    Raycaster,
    Vector2,
    Color,
  } from 'three'

  export default {
    data() {
      return {
        scene: null,
        composer: null,
        outlinePass: null,
        mesh: null,
        clock: null,
        camera: null,
        renderer: null,
        orbitControls: null,
        animationMixer: null,
        doorAction: null,
        locationAction: null,
        activeModel: null,
        modelNameList: ['huoguimen', '1-1', '1-2', '1-3', '1-4', '2-1', '2-2', '2-3', '2-4', '3-1', '3-2', '3-3', '3-4', '4-1', '4-2', '4-3', '4-4', '5-1', '5-2', '5-3', '5-4'],
        canvasEl: null,
        canvasLeft: 0,
        canvasTop: 0,
        group: null,
        doorFlag: false,
        locationFlag: false,
        locationName: null,
        timeoutFlag: false,
        animationDuration: 0,
        huoguiGltf: null,
        flutterJson: '[{"name":"1-1","check":1}]',
        activeColor: {
          r: 0,
          g: 255,
          b: 100,
        },
        defaultColor: {
          r: 0.01269999984651804,
          g: 0.03849999979138374,
          b: 0.01269999984651804,
        },
      }
    },
    watch: {
      activeModel() {
        if (!this.activeModel || this.timeoutFlag) return
        if (this.activeModel.name == 'huoguimen') {
          this.handleDoorAction()
          this.callFlutter(this.activeModel.name, this.doorFlag)
        }
        if (this.activeModel.name.indexOf('-') != -1) {
          if (this.locationName != this.activeModel.name && this.locationFlag) return
          this.locationName = this.activeModel.name
          this.locationAction = this.getAction(this.huoguiGltf, this.activeModel.name + 'Action')
          this.outlineObj([this.activeModel])
          if (!this.locationFlag) {
            this.locationAction.reset()
            this.locationAction.play()
            this.timeoutFlag = true
            setTimeout(() => {
              this.locationAction.paused = true
              this.timeoutFlag = false
            }, (this.animationDuration * 1000) / 2)
            this.locationFlag = true
          } else {
            this.locationAction.paused = false
            this.locationFlag = false
            this.outlineObj([])
          }
          this.callFlutter(this.locationName, this.locationFlag)
        }
      },
    },
    mounted() {
      this.initElement()
      this.initScene()
      this.initCamera()
      this.initGrid()
      this.initPoint()
      this.initListener()
      this.loadModel()
      this.render()
      window.handleActiveModel = this.handleActiveModel
      window.handleResetModel = this.handleResetModel
    },
    methods: {
      /*-----------------------------------------------------------------------开关门的动作-----------------------------------------------------------------------*/
      handleDoorAction() {
        this.doorAction = this.getAction(this.huoguiGltf, 'door')
        if (!this.doorFlag) {
          this.doorAction.reset()
          this.doorAction.play()
          this.timeoutFlag = true
          setTimeout(() => {
            this.doorAction.paused = true
            this.timeoutFlag = false
          }, (this.animationDuration * 1000) / 2)
          this.doorFlag = true
        } else {
          if (!this.locationFlag) {
            this.doorAction.paused = false
            this.doorFlag = false
          }
        }
      },
      /*-----------------------------------------------------------------------高亮模型-----------------------------------------------------------------------*/
      handleActiveModel(flutterJson = '1-1') {
        console.log('开门')
        console.log(flutterJson)
        console.log(typeof flutterJson)
        let arr = flutterJson.split(',')
        console.log(this.scene.children)
        arr.forEach((item) => {
          this.handleActiveChildren(this.scene.children, item)
        })
      },
      handleActiveChildren(list = [], name) {
        list.forEach((item) => {
          if (item.children && item.children.length) {
            this.handleActiveChildren(item.children, name)
          }
          if (item.name == name) {
            item.material.color = this.activeColor
          }
          if (item.name == 'huoguimen') {
            item.visible = false
          }
        })
      },
      /*-----------------------------------------------------------------------重置高亮模型-----------------------------------------------------------------------*/
      handleResetModel(flutterJson = '1-1') {
        console.log('关门')
        let arr = flutterJson.split(',')
        arr.forEach((item) => {
          this.handleResetChildren(this.scene.children, item)
        })
      },
      handleResetChildren(list = [], name) {
        list.forEach((item) => {
          if (item.children && item.children.length) {
            this.handleResetChildren(item.children, name)
          }
          if (item.name == name) {
            item.material.color = this.defaultColor
          }
          if (item.name == 'huoguimen') {
            item.visible = true
          }
        })
      },
      /*-----------------------------------------------------------------------传数据给flutter-----------------------------------------------------------------------*/
      callFlutter(name, bool) {
        try {
          // eslint-disable-next-line no-undef
          JSHandle.postMessage(`{name:${name},type:${bool}}`)
        } catch (e) {
          console.log(e)
        }
      },
      getAction(gltf, actionName) {
        const animation = gltf.animations.find((animationClip) => animationClip.name == actionName)
        const action = this.animationMixer.clipAction(animation)
        action.setLoop('LoopOnce', 1)
        this.animationDuration = animation.duration
        return action
      },
      /*-----------------------------------------------------------------------外部模型导入-----------------------------------------------------------------------*/
      loadModel() {
        this.group = new Group()
        const gltfLoader = new GLTFLoader()
        gltfLoader.load('/static/gltf/huogui.gltf', (gltf) => {
          console.log(gltf)
          this.huoguiGltf = gltf
          let gltf1 = gltf.scene.clone()
          gltf1.name = 'huoguiti'
          gltf1.position.set(0, 0, 3)
          //      this.orbitControls.target.set(0, 1, 0);
          this.group.add(gltf1)
          this.scene.add(this.group)

          //          let gltf2 = gltf.scene.clone()
          //          gltf2.name = 'huogui2'
          //          this.modelNameList.push(gltf2.name)
          //          gltf2.position.set(4, 4, 4)
          //          this.scene.add(gltf2)
        })
        //        gltfLoader.load('/static/gltf/huoguimen.gltf', gltf => {
        //          let gltf1 = gltf.scene.clone()
        //          gltf1.name = 'huoguimen'
        //          this.modelNameList.push(gltf1.name)
        //          this.group.add(gltf1)
        //          this.scene.add(this.group)
        //        })
        gltfLoader.load('/static/gltf/fangjian1.gltf', (gltf) => {
          gltf.scene.name = 'fangjian'
          //          this.group.add(gltf.scene)
          //          this.scene.add(this.group)
        })
      },
      /*-----------------------------------------------------------------------场景-----------------------------------------------------------------------*/
      initScene() {
        this.scene = new Scene()
        this.clock = new Clock()
        this.renderer = new WebGLRenderer({ antialias: true })
        this.renderer.setSize(this.canvasInnerWidth, this.canvasInnerHeight)
        this.renderer.shadowMap.enabled = true
        this.renderer.setClearColor(0x01192c, 1) //设置背景颜色
        this.canvasEl.appendChild(this.renderer.domElement)
        //        const axes = new AxesHelper(10)
        //        this.scene.add(axes)
        this.animationMixer = new AnimationMixer(this.scene)
      },
      /*-----------------------------------------------------------------------地面-----------------------------------------------------------------------*/
      initGrid() {
        // 矩形平面
        const planeBufferGeometry = new PlaneBufferGeometry(100, 100)
        const plane = new Mesh(planeBufferGeometry, new MeshBasicMaterial({ color: 0x01192c, side: DoubleSide }))
        plane.name = 'plane'
        plane.rotation.x = -Math.PI / 2
        this.scene.add(plane)
        this.scene.add(new GridHelper(100, 100, 0x0a4e6a, 0x0a4e6a))
      },
      /*-----------------------------------------------------------------------灯源-----------------------------------------------------------------------*/
      initPoint() {
        // 光源
        let point = new PointLight(0xffffff)
        point.position.set(10, 100, 300)
        this.scene.add(point)
        let ambient = new AmbientLight(0xffffff)
        this.scene.add(ambient)
      },

      /*-----------------------------------------------------------------------相机----------------------------------------------------------------------*/
      initCamera() {
        // 透视相机设置
        this.camera = new PerspectiveCamera(45, this.canvasInnerWidth / this.canvasInnerHeight, 0.01, 1000)
        //        this.camera.position.set(-9, 7, 9)
        this.camera.position.set(1.5, 3, 8)
        //设置相机方向(指向的场景对象)
        this.camera.lookAt(this.scene.position)
      },

      /*-----------------------------------------------------------------------点击事件处理-----------------------------------------------------------------------*/
      onMouseClick(event) {
        //添加光投射器Raycaster
        let raycaster = new Raycaster()
        let mouse = new Vector2()
        // 通过鼠标点击的位置计算出raycaster所需要的点的位置，以屏幕中心为原点，值的范围为-1到1.
        if (event.touches) {
          mouse.x = ((event.touches[0].pageX - this.canvasLeft) / this.canvasEl.clientWidth) * 2 - 1
          mouse.y = -((event.touches[0].pageY - this.canvasTop) / this.canvasEl.clientHeight) * 2 + 1
        } else {
          mouse.x = ((event.clientX - this.canvasLeft) / this.canvasEl.clientWidth) * 2 - 1
          mouse.y = -((event.clientY - this.canvasTop) / this.canvasEl.clientHeight) * 2 + 1
        }
        // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
        raycaster.setFromCamera(mouse, this.camera)
        // 鼠标点击对应的物体（所有鼠标映射到的物体，包括被遮挡的）
        const intersects = raycaster.intersectObjects(this.scene.children, true)
        // 过滤网格和地面
        const intersect = intersects.filter((intersect) => !(intersect.object instanceof GridHelper) && !(intersect.object instanceof AxesHelper) && intersect.object.name !== 'plane')[0]
        if (intersect) this.handleIntersect(intersect)
      },
      handleIntersect(intersect) {
        this.activeModel = null
        for (const item of this.modelNameList) {
          this.activeModel = this.isClickModel(intersect.object, item)
          if (this.activeModel) break
        }
      },
      isClickModel(obj3D, name) {
        if (obj3D.name == name) {
          return obj3D
        } else if (obj3D.parent && obj3D.parent.name) {
          return this.isClickModel(obj3D.parent, name)
        } else {
          return null
        }
      },
      /*-----------------------------------------------------------------------高亮显示模型-----------------------------------------------------------------------*/
      outlineObj(selectedObjects) {
        // 创建一个EffectComposer（效果组合器）对象，然后在该对象上添加后期处理通道。
        this.composer = new EffectComposer(this.renderer)
        // 新建一个场景通道  为了覆盖到原理来的场景上
        this.renderPass = new RenderPass(this.scene, this.camera)
        this.composer.addPass(this.renderPass)
        // 物体边缘发光通道
        this.outlinePass = new OutlinePass(new Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera, selectedObjects)
        this.outlinePass.selectedObjects = selectedObjects
        this.outlinePass.edgeStrength = 10.0 // 边框的亮度
        this.outlinePass.edgeGlow = 1 // 光晕[0,1]
        this.outlinePass.usePatternTexture = false // 是否使用父级的材质
        this.outlinePass.edgeThickness = 3.0 // 边框宽度
        this.outlinePass.downSampleRatio = 1 // 边框弯曲度
        this.outlinePass.pulsePeriod = 5 // 呼吸闪烁的速度
        this.outlinePass.visibleEdgeColor.set(parseInt(0x00ff00)) // 呼吸显示的颜色
        this.outlinePass.hiddenEdgeColor = new Color(0, 0, 0) // 呼吸消失的颜色
        this.outlinePass.clear = true
        this.composer.addPass(this.outlinePass)
      },
      /*-----------------------------------------------------------------------初始化监听事件-----------------------------------------------------------------------*/
      initListener() {
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
        window.addEventListener('resize', () => this.onWindowResize())
        window.addEventListener('click', this.onMouseClick, false)
        window.addEventListener('touchstart', this.onMouseClick, false)
      },
      onWindowResize() {
        this.initElement()
        this.renderer.setSize(this.canvasInnerWidth, this.canvasInnerHeight)
        this.camera.aspect = this.canvasInnerWidth / this.canvasInnerHeight
        this.camera.updateProjectionMatrix()
      },
      /*-----------------------------------------------------------------------初始化宽高-----------------------------------------------------------------------*/
      initElement() {
        this.canvasEl = document.getElementById('app')
        this.canvasLeft = this.canvasEl.getBoundingClientRect().left
        this.canvasTop = this.canvasEl.getBoundingClientRect().top
        this.canvasInnerWidth = this.canvasEl.offsetWidth
        this.canvasInnerHeight = this.canvasEl.offsetHeight
      },
      /*-----------------------------------------------------------------------绘制页面-----------------------------------------------------------------------*/
      render() {
        // 更新动画
        this.animationMixer.update(this.clock.getDelta())
        this.renderer.render(this.scene, this.camera)
        this.orbitControls.update()
        window.requestAnimationFrame(() => this.render())
        if (this.composer) {
          this.composer.render()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
  }
</style>
